import { React, useEffect,lazy, Suspense } from 'react';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import AOS from 'aos';
import 'aos/dist/aos.css';
import CommercialBtn from "../assets/Home/commercial.webp"
import ResidentialBtn from "../assets/Home/residential.webp"
import SolarBtn from "../assets/Home/solar.webp"
import TechBtn from "../assets/Home/tech.webp"

import BusinessIcon from '@material-ui/icons/Business';
import HomeIcon from '@material-ui/icons/Home';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import ComputerIcon from '@material-ui/icons/Computer';
import MouseIcon from '@material-ui/icons/Mouse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import WhatWeDoImg from "../assets/AboutUs/whatWeDo.svg"
import DifferentImg from "../assets/AboutUs/different.svg"
import HowWeHelpImg from "../assets/AboutUs/howWeHelp.svg"
import StatesImg from "../assets/OurTeam/map.webp"
//import { Ring } from '@uiball/loaders'

import Carousel from "../components/Carousel"
//const Carousel = lazy(() => import("../components/Carousel"));

const ContactForm = lazy(() => import("../components/contactForm"));
//Lazy imports allows the chunk size to be lowered allowing very fast rendering of the Website!
//Look at this URL: https://web.dev/code-splitting-suspense/?utm_source=lighthouse&utm_medium=lr
//import ContactForm from "../components/contactForm"


const Home = () => {
    const renderLoader = () =><h1></h1>;
    useEffect(() => {
        AOS.init({ duration: 1200 });    
    }, []);
    return (  
        <div>
            <Helmet>
                <title>Rave Energy | Home</title>
                <meta name="keywords" content="HTML,CSS,JavaScript" />
                <meta
                    name="description"
                    content="Rave Energy home page."
                />
            </Helmet>
            <main>
                <div className="flex sm:flex-row flex-col">
                        <Carousel />
                    <a href="#featured-resources" className="bg-red-500 hidden md:block xl:block lg:block absolute items-center bottom-0 left-0 w-48 h-20 text-white rounded-tr-full pt-4 px-16 ">
                        <div className="flex flex-col justify-center hover:animate-bounce">
                        <MouseIcon className="mt-2 " style={{ fontSize: `30px`}} />
                        <ExpandMoreIcon className="-mt-2 " style={{ fontSize: `30px`}} />
                        </div>
                    </a>
                    <div className="flex-col sm:w-2/5 h-0 sm:h-screen md:h-screen lg:h-screen w-screen hidden sm:flex md:flex lg:flex xl:flex">
                        <a className="h-1/4" href="https://commercial.raveenergy.net/">
                            <div className="flex flex-col justify-center items-center text-white sm:text-3xl h-full bg-center bg-140 lg:bg-130 lg:hover:bg-140 transition-all duration-1000 border border-black" style={{ backgroundImage: `url(${CommercialBtn})`}}>
                                <BusinessIcon style={{ fontSize: `40px`}} />
                                <hr className="white w-1/2 border hidden sm:block"/>
                                <h1 className="sm:mt-2 font-medium">COMMERCIAL</h1>
                            </div>
                        </a>
                        <a className="h-1/4" href="https://home.raveenergy.net">
                            <div className="flex flex-col justify-center items-center text-white sm:text-3xl h-full bg-center bg-140 lg:bg-130 lg:hover:bg-140 transition-all duration-1000 border border-black" style={{ backgroundImage: `url(${ResidentialBtn})`}}>
                                <HomeIcon style={{ fontSize: `40px`}} />
                                <hr className="white w-1/2 border hidden sm:block" />
                                <h1 className="sm:mt-2 font-medium">RESIDENTIAL</h1> 
                            </div>
                        </a>
                        <a className="h-1/4" href="https://heliosenergy.us">
                            <div className="flex flex-col justify-center items-center text-white sm:text-3xl h-full bg-center bg-140 lg:bg-130 lg:hover:bg-140 transition-all duration-1000 border border-black" style={{ backgroundImage: `url(${SolarBtn})`}}>
                                <WbSunnyIcon style={{ fontSize: `40px`}} />
                                <hr className="white w-1/2 border hidden sm:block" />
                                <h1 className="sm:mt-2 font-medium">SOLAR</h1>
                            </div>
                        </a>
                        <Link className="h-1/4" to="/technology">
                            <div className="flex flex-col justify-center items-center text-white sm:text-3xl h-full bg-center bg-140 lg:bg-130 lg:hover:bg-140 transition-all duration-1000 border border-black" style={{ backgroundImage: `url(${TechBtn})`}}>
                                <ComputerIcon style={{ fontSize: `40px`}} />
                                <hr className="w-1/2 border hidden sm:block" />
                                <h1 className="sm:mt-2 font-medium">TECHNOLOGY</h1>
                            </div>
                        </Link>
                    </div>
                </div>
                    <div>
                        <div>
                            <div className='flex justify-center align-middle my-8'>
                                <a href="https://commercial.raveenergy.net/"><button className='visible sm:hidden md:hidden lg:hidden xl:hidden h-12 w-20  bg-red-600 rounded-xl mx-1 py-4 font-bold text-white text-xs '>Commercial</button></a>
                                <a href="https://home.raveenergy.net/"><button className='visible sm:hidden md:hidden lg:hidden xl:hidden h-12 w-20 bg-red-600 rounded-xl mx-1  py-4 font-bold text-white text-xs'>Residential</button></a>
                                <a href="https://www.heliosenergy.us/"><button className='visible sm:hidden md:hidden lg:hidden xl:hidden h-12 w-20 bg-red-600 rounded-xl mx-1  py-4 font-bold text-white text-xs '>Solar</button></a>
                                <Link to="/technology"><button className='visible sm:hidden md:hidden lg:hidden xl:hidden h-12 w-20 bg-red-600 rounded-xl mx-1  py-4 font-bold text-white text-xs'>Tech</button></Link>
                            </div>
                        </div>
                    </div>
                <div  className="flex flex-col justify-center items-center  sm:-mt-0 md:mt-0 lg:mt-0 xl:mt-0">
                    <h1 id="featured-resources" style={{ color : `#2C3E50` }} className="font-medium tracking-tighter sm:text-6xl text-4xl text-center">Featured<span className="text-red-500"> Services</span></h1>
                    <div data-aos-ease="ease" data-aos-duration="400" data-aos="fade-up" className="flex flex-col md:flex-col lg:flex-row mt-2 sm:mt-16 md:mt-16 lg:mt-16 xl:mt-16">
                        <div style={{ color : `#2C3E50` }}  className="border border-grey-400 bg-white rounded-xl tracking-tighter max-w-sm h-72 mx-8 md:mx-4 mt-8 lg:mt-0 lg:hover:shadow-md transform lg:hover:-translate-y-9 transition-all duration-700">
                            <h2 className="font-normal lg:text-3xl md:text-2xl text-2xl text-center bg-red-500 rounded-t-xl py-2 text-white">Demand Response</h2>
                            <p className="font-light sm:text-justify text-normal xl:text-lg text-center mt-4 px-4">Provides an opportunity for consumers to play a significant role in the operation of the electric grid by reducing or shifting their electricity usage during peak periods in response to time-based rates or other forms of financial incentives.</p>
                        </div>
                        <div style={{ color : `#2C3E50` }} className="border border-grey-400 bg-white rounded-xl tracking-tighter max-w-sm h-72 mx-8 md:mx-4 mt-8 lg:mt-0 lg:hover:shadow-md transform lg:hover:-translate-y-9 transition-all duration-700">
                            <h2 className="font-normal lg:text-3xl md:text-2xl text-2xl text-center bg-red-500 rounded-t-xl py-2 text-white">Energy Intelligence Suite</h2>
                            <p className="font-light sm:text-justify text-normal xl:text-lg text-center mt-4 px-4">Powered through our Network Operations Center (NOC), Rave’s Energy Intelligence Suite (EIS) enables energy teams across North America to make intelligent energy decisions, saving them resources, time, and money every day.</p>
                        </div>
                        <div style={{ color : `#2C3E50` }} className="border border-grey-400 bg-white rounded-xl tracking-tighter max-w-sm h-72 mx-8 md:mx-4 mt-8 lg:mt-0 lg:hover:shadow-md transform lg:hover:-translate-y-9 transition-all duration-700">
                            <h2 className="font-normal lg:text-3xl md:text-2xl text-2xl text-center bg-red-500 rounded-t-xl py-2 text-white">Energy Efficiency Audit</h2>
                            <p className="font-light sm:text-justify text-normal xl:text-lg text-center mt-4 px-4">We integrate data-driven insights with the latest energy technologies to help you make strategic decisions to transform your building systems into efficient and sustainable environments to work, learn, and thrive.</p>
                        </div>
                    </div>
                </div>
                <div className="pb-6 lg:pb-16 xl:pb-16">
                    <div className="flex justify-center items-center py-6 lg:py-16 xl:py-16 text-center lg:text-left xl:text-left">
                        <img alt="What rave energy does" src={WhatWeDoImg} height="100%" width="100%" className="hidden lg:block xl:block h-72 w-72 mx-16 object-cover rounded" />
                        <div className="mx-2 lg:mx-16 xl:mx-16">
                            <h2 className="text-4xl font-medium lg:max-w-md xl:max-w-md">What We Do</h2>
                            <p className="sm:max-w-lg md:max-w-lg mx-auto lg:mx-0 xl:mx-0 lg:max-w-lg xl:max-w-lg mt-4 text-lg font-light">Rave energy is one of the most technologically advanced providers of distributed energy resource asset optimization, demand response services, and energy management information systems.</p>
                        </div>
                    </div>
                    <div className="flex justify-center items-center text-center lg:text-left xl:text-left py-6 lg:py-16 xl:py-16">
                        <div className="mx-2 lg:mx-16 xl:mx-16">
                            <h2 className="text-4xl font-medium lg:max-w-md xl:max-w-md">What Makes Us different</h2>
                            <p className="sm:max-w-lg md:max-w-lg mx-auto lg:mx-0 xl:mx-0 lg:max-w-lg xl:max-w-lg mt-4 text-lg font-light">Innovative Software Solutions: Our AI-based software efficiently optimizes energy usage in real-time. Clients save big with our solutions and from our comprehensive dashboard view, our clients get a better understanding of their energy usage.</p>
                        </div>
                        <img alt="What makes rave energy different" src={DifferentImg} height="100%" width="100%" className="hidden lg:block xl:block h-72 w-72 mx-16 object-cover rounded" />
                    </div>
                    <div className="flex justify-center items-center py-6 lg:py-16 xl:py-16 text-center lg:text-left xl:text-left">
                        <img alt="How rave energy helps" src={HowWeHelpImg} height="100%" width="100%" className="hidden lg:block xl:block h-72 w-72 mx-16 object-cover rounded" />
                            <div className="mx-2 lg:mx-16 xl:mx-16">
                                <h2 className="text-4xl font-medium lg:max-w-md xl:max-w-md">How We Help</h2>
                                <p className="sm:max-w-lg md:max-w-lg mx-auto lg:mx-0 xl:mx-0 lg:max-w-lg xl:max-w-lg mt-4 text-lg font-light">We help develop sustainable energy solutions for our clients, our expertise helps our clients minimize their energy costs, maximize efficiency, and enhance power system reliability.</p>
                            </div>
                    </div>
                </div>
                <div className="text-center px-4 py-8 lg:px-0 h-full">
                    <h1 style={{ color : `#2C3E50` }} className="font-medium tracking-tighter sm:text-6xl text-4xl mt-6">States<span className="text-red-500"> We</span> Serve</h1>
                    <p style={{ color : `#2C3E50` }} className="font-light mt-4">Below are states we serve offering electricity and/or natural gas procurement</p>
                    <img data-aos-ease="ease" data-aos-duration="400" data-aos="zoom-in" className="mx-auto" width="1000px" height="750px" src={StatesImg} alt="States We Service"/>
                </div>
                <div data-aos-ease="ease" data-aos-duration="400" data-aos="zoom-in">
                    <Suspense fallback={renderLoader()}
                    ><ContactForm/>
                    </Suspense>
                </div>
            </main>
        </div>
    );
}
 
export default Home;