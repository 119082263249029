import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { Helmet } from "react-helmet";
import Paywall from "../components/Paywall.js";

const ProcurementDownload = () => {
    useEffect(() => {
        AOS.init({ duration: 1200 });    
    }, []);
    return (
        <main>
            <Helmet>
                <title>Energy Procurement Management | Download</title>
                <meta name="keywords" content="HTML,CSS,JavaScript" />
                <meta
                    name="description"
                    content="Energy Procurement Management"
                />
            </Helmet>
            <div className="h-56 sm:h-40 md:h-40 lg:h-40 xl:h-40" style={{ background: `#00102f`}}>
                <h1 className="font-bold text-center tracking-tighter text-3xl md:text-4xl lg:text-5xl pt-24 sm:pt-24 md:pt-24 lg:pt-12 xl:pt-12 text-white">Energy Procurement Managment</h1>
                
            </div>
            <Paywall route={"procurement"} />
        </main>
    );
}
 
export default ProcurementDownload;