import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import Proc from "../assets/Technology/proc.webp"
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import GetAppIcon from '@material-ui/icons/GetApp';

const Management = () => {
    useEffect(() => {
        AOS.init({ duration: 1200 });    
    }, []);
    return (
        <main>
            <Helmet>
                <title>Rave Energy | Energy Management Platform</title>
                <meta name="keywords" content="HTML,CSS,JavaScript" />
                <meta
                    name="description"
                    content="Rave Energy Energy Management Platform"
                />
            </Helmet>
            <div style={{ color : `#2C3E50` }} className="flex flex-col items-center">
                <img alt="Procure" className="hidden lg:block w-screen h-96 object-cover object-bottom" src={Proc} height="100%" width="100%" />
                <h1 className="font-bold text-center tracking-tighter text-3xl md:text-4xl lg:text-5xl pt-24 sm:pt-24 md:pt-24 lg:pt-12 xl:pt-12">Energy Management Platform</h1>
                <p className="sm:text-justify text-center text-xl max-w-4xl mt-4 px-4 md:px-0 lg:px-0">Rave Energy Property Management Solution solves issues such as high administrative
costs that come with manual reporting, siloed data between numerous service
providers, difficulty identifying ROI of efficiency projects, sustainability reporting
requirements, and more.</p>
                <div className="flex justify-center"><Link style={{color: "00102f" }} to="/technology/management/download" target="_blank" className="font-semibold rounded-md bg-gray-200 hover:bg-gray-400 duration-300 my-8 py-4 px-4"><GetAppIcon className="mr-3" />Download a PDF for more Information</Link></div>
            </div>
        </main>
    );
}
 
export default Management;