import React, { useState } from 'react';
import { Link } from "react-router-dom";

import "../components/dropdown.css";

import Logo from "../assets/R.png"

import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';



const Nav = () => {
    const toggleOverlay = () =>  {
        const overlay = document.getElementById('overlay');
        const top = document.getElementById("top");
        const middle = document.getElementById("middle");
        const bottom = document.getElementById("bottom");
        const body = document.body;
        if(overlay.style.opacity === "1") {
            overlay.style.zIndex = "-1";
            overlay.style.opacity = "0";
            body.style.overflow = "visible"  
            top.style.transform = "translateY(0px) translateX(0) rotate(0deg)";
            middle.style.opacity = "100%";
            bottom.style.transform = "translateY(0px) translateX(0) rotate(0deg)";
        }
        else {
            overlay.style.zIndex = "40";
            overlay.style.opacity = "1";
            body.style.overflow = "hidden"
            top.style.transform = "translateY(7px) translateX(0) rotate(45deg)";
            middle.style.opacity = "0%";
            bottom.style.transform = "translateY(-7px) translateX(0) rotate(-45deg)";
        }
    }
    const [toggle, setToggle] = useState(false)
    const accordionToggle = () => {
        if(toggle){
            document.getElementById("acc").style.height="0rem"
            setToggle(false)
        }
        else{
            document.getElementById("acc").style.height="10rem"
            setToggle(true)
        }   
    }
    return ( 
        <nav>
            <div className="fixed h-screen z-50">
                <div className="absolute z-50 cursor-pointer left-9 top-9 w-24 rounded-full bg-red-500 shadow-md">
                    <Link to="/"><img className="relative top-2 ml-4 mt-1" src={Logo} width="20px" height="30px" alt="Rave Energy Logo" /></Link>
                    <div onClick={toggleOverlay} className="relative w-6 ml-7 transform -translate-x-1/2 -top-4 left-9">
                        <div className="w-full h-3px bg-white transition-all mt-1" id="top" ></div>
                        <div className="w-full h-3px bg-white transition-all mt-1" id="middle" ></div>
                        <div className="w-full h-3px bg-white transition-all mt-1" id="bottom" ></div>
                    </div>
                </div>
            </div>
            <div id="overlay"  style={{ backgroundColor: `#00102f`, opacity : `0`, zIndex : `-1` }} className="flex flex-col fixed bg-cover bg-center top-0 left-0 sm:w-full w-screen h-screen overflow-hidden transition-all duration-300">
                <div>
                    <ul className="text-white ml-0 md:ml-20 lg:ml-20 xl:ml-20 list-none text-center md:text-left lg:text-left xl:text-left">
                        <li onClick={toggleOverlay} className="mt-32 xsLandscape:mt-8 sm:mt-8 md:mt-32 lg:mt-32 xl:mt-32">
                            <Link to="/" className="cursor-pointer font-medium tracking-tighter no-underline hover:text-red-500 text-4xl lg:text-5xl xl:text-5xl transition duration-180">Home</Link></li>
                        <li className=" hidden md:block lg:w-96 h-16 mt-2 sm:mt-2 md:mt-8 lg:mt-8 xl:mt-8">
                            <button className="font-medium no-underline hover:text-red-500 text-4xl lg:text-5xl xl:text-5xl transition duration-180"> Services
                                <span>
                                    <svg
                                        className="inline-block fill-current w-8 h-20 transition hover:text-red-500 duration-200 ease-in-out groupa:rotate-180"
                                        viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                                    </svg>
                                </span>
                            </button>
                            <ul className="text-black md:text-white lg:text-white xl:text-white lg:bg-transparent xl:bg-transparent md:bg-transparent bg-white cursor-pointer font-light sm:text-3xl no-underline text-5xl groupa-hover:scale-100 scale-0 relative md:-top-20 lg:-top-20 xl:-top-20 md:left-48 lg:left-48 xl:left-48 -left-96 h-72 md:w-48 lg:w-48 xl:w-48 rounded-lg transition duration-150 ease-in-out ">
                                <li className="px-3 py-1 hover:text-red-500"><a href="https://commercial.raveenergy.net" className="cursor-pointer font-medium tracking-tighter no-underline hover:text-red-500 text-4xl lg:text-5xl xl:text-5xl transition duration-180">Commercial</a></li>
                                <li className="px-3 py-1 hover:text-red-500 mt-4"><a href="https://home.raveenergy.net" className="cursor-pointer font-medium tracking-tighter no-underline hover:text-red-500 text-4xl lg:text-5xl xl:text-5xl transition duration-180">Residential</a></li>
                                <li className="px-3 py-1 hover:text-red-500 mt-4"><a href="https://heliosenergy.us" className="cursor-pointer font-medium tracking-tighter no-underline hover:text-red-500 text-4xl lg:text-5xl xl:text-5xl transition duration-180">Solar</a></li>
                                <li onClick={toggleOverlay} className="px-3 py-1 hover:text-red-500 mt-4"><Link to="/technology" className="cursor-pointer font-medium tracking-tighter no-underline hover:text-red-500 text-4xl lg:text-5xl xl:text-5xl transition duration-180">Technology</Link></li>
                            </ul>
                        </li> 
                        <div className="block md:hidden lg:hidden xl:hidden flex justify-center items-center w-screen p-2">
                            <div className="flex flex-col w-56">
                                <button className="group ">
                                    <div className="flex items-center justify-between font-semibold">
                                        <span onClick={accordionToggle} className="truncate text-4xl ml-8 ">Services</span>
                                        <svg onClick={accordionToggle} className="h-8 mr-4" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </div>
                                    <div style={{height:0}} className="overflow-hidden duration-300" id="acc">
                                        <li className="flex items-center h-10 px-16 text-2xl"><a href="https://commercial.raveenergy.net">Commercial</a></li>
                                        <li className="flex items-center h-10 px-16 text-2xl"><a href="https://home.raveenergy.net">Residential</a></li>
                                        <li className="flex items-center h-10 px-16 text-2xl"><a href="https://heliosenergy.us">Solar</a></li>
                                        <li onClick={toggleOverlay} className="flex items-center h-10 px-16 text-2xl"><Link to="/technology">Technology</Link></li>
                                    </div>
                                </button>
                            </div>
                        </div>
                        <li onClick={toggleOverlay} className="mt-2 sm:mt-2 md:mt-8 lg:mt-8 xl:mt-8">
                            <Link to="/contact" className="cursor-pointer font-medium tracking-tighter no-underline hover:text-red-500 text-4xl lg:text-5xl xl:text-5xl transition duration-180">Contact</Link>
                        </li>
                        <li className="mt-2 sm:mt-2 md:mt-8 lg:mt-8 xl:mt-8">
                            <a href="https://blog.theravecompany.net" className="cursor-pointer font-medium tracking-tighter no-underline hover:text-red-500 text-4xl lg:text-5xl xl:text-5xl transition duration-180">Blog</a>
                        </li>
                        <li className="mt-2 sm:mt-2 md:mt-8 lg:mt-8 xl:mt-8">
                            <a href="https://www.theravecompany.net/" className="cursor-pointer font-medium tracking-tighter no-underline hover:text-red-500 text-4xl lg:text-5xl xl:text-5xl transition duration-180">More From Rave</a>
                        </li>
                    </ul>
                    <div id="socials" className="text-white flex justify-center lg:justify-start xl:justify-start sm:ml-20 mt-12 sm:mt-10 md:mt-16 lg:mt-16 xl:mt-16">
                        <a className="mx-8 sm:ml-0 sm:mr-16 hover:text-red-500 transition duration-500" href="https://www.facebook.com/raveenergy2020">
                            <FacebookIcon style={{ fontSize: `40px`}} />
                        </a>
                        <a className="mx-8 sm:mx-16 hover:text-red-500 transition duration-500" href="https://instagram.com/the_rave_company">
                            <InstagramIcon style={{ fontSize: `40px`}} />
                        </a>
                        <a className="mx-8 sm:mx-16 hover:text-red-500 transition duration-500" href="https://linkedin.com/company/raveenergy">
                            <LinkedInIcon style={{ fontSize: `40px`}} />
                        </a>
                    </div>
                </div>
            </div>
        </nav>
    );
}
 
export default Nav;
