import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import Tech from "../assets/Technology/tech.svg"
import Demand from "../assets/Technology/demand.svg"
import Market from "../assets/Technology/market.svg"
import Notification from "../assets/Technology/notification.svg"
import Property from "../assets/Technology/property.svg"
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import renew from "../assets/Technology/renew.webp"
import solution from "../assets/Technology/solution.webp"

const Technology = () => {
    useEffect(() => {
        AOS.init({ duration: 1200 });    
    }, []);
    return (
        <div>
            <Helmet>
                <title>Rave Energy | Technology</title>
                <meta name="keywords" content="HTML,CSS,JavaScript" />
                <meta
                    name="description"
                    content="Our technology services."
                />
            </Helmet>
            <main>
                <div className="h-56 sm:h-40 md:h-40 lg:h-40 xl:h-40" style={{ background: `#00102f`}}>
                    <h1 className="font-bold text-center tracking-tighter text-3xl md:text-4xl lg:text-5xl pt-24 sm:pt-24 md:pt-24 lg:pt-12 xl:pt-12 text-white">Technology Driven Energy Consulting</h1>
                </div>
                <div className="flex items-center justify-center content-around sm:px-4 md:px-0 lg:px-4">
                    <div style={{ color : `#2C3E50` }} >
                        <h1 style={{ color : `#2C3E50` }} className="font-medium tracking-tighter sm:text-5xl text-4xl mt-4 sm:mt-6 md:mt-6 lg:mt-6 xl:mt-6 text-center md:text-left lg:text-left xl:text-left">The <span className="text-red-500"> Tech </span> Behind It All</h1>
                        <p className="sm:text-justify text-center text-xl max-w-2xl mt-4 px-4 md:px-0 lg:px-0">Our unique platform connects to your building management systems, batteries, generators, and other energy-related devices and regulates them in real-time, providing you complete control over your energy consumption. Track your energy assets, get insights, and create event schedules all in one convenient location. All of this is made possible by our in-house automation team, which collaborates with you to set up integration and controls.</p>
                    </div>
                    <img alt="Rave Energy Technology" className="hidden lg:block" src={Tech} height="390" width="400" />
                </div>
                <div className="flex flex-col lg:flex-row items-center justify-center mt-4 sm:mt-16 md:mt-16 lg:mt-16 xl:mt-16">
                    <Link to="/technology/procurement" className="flex flex-col items-center content-center max-w-sm mx-8 rounded-lg bg-gray-100 shadow-md transform hover:-translate-y-3 duration-300 cursor-pointer mt-6 lg:mt-0 xl:mt-0">
                        <img className="w-full h-64 rounded-t-lg" src={renew} alt="Energy Procurement"/>
                        <div class="p-4 text-center rounded-lg bg-gray-100">
                            <h1 className="font-semibold text-dark text-xl md:text-xl xl:text-xl mb-4 block">Energy Procurement Management</h1>
                            <p class="leading-relaxed mb-7"> Energy procurement is the strategic process of obtaining your energy needs from a third party energy supplier or local distribution company (LDC).</p>
                            <p class="py-2 px-7 font-medium">Read More</p>
                        </div>   
                    </Link>
                    <Link to="/technology/management" className="flex flex-col items-center content-center max-w-sm mx-8 rounded-lg bg-gray-100 shadow-md transform hover:-translate-y-3 duration-300 cursor-pointer mt-6 lg:mt-0 xl:mt-0">
                        <img className="w-full h-64 rounded-t-lg" src={solution} alt="Energy Management Platform"/>
                        <div class="p-8 sm:p-9 md:p-7 text-center rounded-lg bg-gray-100">
                            <h1 className="font-semibold text-dark text-xl md:text-xl xl:text-xl mb-4 block">Energy Management Platform</h1>
                            <p class="leading-relaxed mb-7"> Energy Management Software is an overall term and class alluding to an assortment of energy-related programming applications. </p>
                            <p class="py-2 px-7 font-medium">Read More</p>
                        </div>   
                    </Link>
                </div>
                <h1 style={{ color : `#2C3E50` }} className="font-medium tracking-tighter sm:text-6xl text-4xl mt-32 sm:mt-32 md:mt-32 lg:mt-32 xl:mt-32 text-center">What<span className="text-red-500"> We </span> Do</h1>
                <div data-aos-ease="ease" data-aos-duration="400" data-aos="fade-up" className="flex flex-col lg:flex-row items-center justify-center ">
                    <div style={{ color : `#2C3E50` }} className="flex flex-col items-center content-center max-w-sm mx-8 h-96 mt-16">
                        <img className="m-2 w-44" src={Demand} alt="Demand Response" height="200" width="300" />
                        <div className="p-4 h-full">
                            <h1 className="font-medium text-center text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-3xl tracking-tighter">Demand Response</h1>
                            <p className="sm:text-justify text-center tracking-tighter mt-1 text-sm sm:text-base md:text-base lg:text-base xl:text-base">Rave turns your energy consumption into a money-making asset! Our powerful AI-assisted reporting tool helps to keep track of your portfolio’s energy consumption and ensures optimal dispatching to keep things running smoothly.</p>
                        </div>
                    </div>
                    <div style={{ color : `#2C3E50` }} className="flex flex-col items-center content-center max-w-sm mx-8 h-96 mt-16">
                        <img className="m-2 w-44" src={Market} alt="Market Dashboard" height="200" width="300" />
                        <div className="p-4 h-full">
                            <h1 className="font-medium text-center text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-3xl tracking-tighter">Market Dashboard</h1>
                            <p className="sm:text-justify text-center tracking-tighter mt-1 text-sm sm:text-base md:text-base lg:text-base xl:text-base">Rave has created a market dashboard that enables our clients to track the energy market in real-time and receive alerts for recommended energy purchase triggers.</p>
                        </div>    
                    </div>
                </div>
                <div data-aos-ease="ease" data-aos-duration="400" data-aos="fade-up" className="flex flex-col lg:flex-row items-center justify-center mb-8">
                    <div style={{ color : `#2C3E50` }} className="flex flex-col items-center content-center max-w-sm mx-8 h-96 mt-16">
                        <img className="m-2 w-44" src={Notification} alt="Notifications" height="200" width="300" />
                        <div className="p-4 h-full">
                            <h1 className="font-medium text-center text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-3xl tracking-tighter">Notifications</h1>
                            <p className="sm:text-justify text-center tracking-tighter mt-1 text-sm sm:text-base md:text-base lg:text-base xl:text-base">We have a set of custom notifications based on the business type. From outage prediction to peak notification and contract end dates, we will provide you with the most relevant information in the most convenient way possible.</p>
                        </div>
                    </div>
                    <div style={{ color : `#2C3E50` }} className="flex flex-col items-center content-center max-w-sm mx-8 h-96 mt-16">
                        <img className="m-2 w-44" src={Property} alt="Property Management" height="200" width="300" />
                        <div className="p-4 h-full">
                            <h1 className="font-medium text-center text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-3xl tracking-tighter">Property Management </h1>
                            <p className="sm:text-justify text-center tracking-tighter mt-1 text-sm sm:text-base md:text-base lg:text-base xl:text-base">We provide cloud-based account management that helps customers manage all their properties in the most convenient way possible.</p>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
 
export default Technology;