import { useState } from 'react';
import { useForm } from 'react-hook-form';
import ProspectDataServices from "../services/prospects";
import SuccessImg from "../assets/success.svg"

const Form = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [submitted, setSubmitted] = useState(false);
    const [infoSubmitted, setInfoSubmitted] = useState(false);
    const googleScript = "https://script.google.com/a/gmail.com/macros/s/AKfycbyYFT-bC89ElRb26kIxDX8YkHBENyXE7mEwri8jhpsaubQF5FIxOMtU7sNFVq4V6jLU/exec";
    async function onSubmitForm(values){
        const { name, email, phone, message } = values;
        var prospect = {
            name: name,
            email: email,
            phone: phone,
            message: message,
        };
        ProspectDataServices.createProspect(prospect)
        .then(response => {
            setInfoSubmitted(true);
            console.log(response.data);
            
        })
        .catch(e =>{
            console.log(e);
        });
    }
    return ( 
        <main className="flex flex-col items-center content-center justify-center bg-cover pt-8 pb-16 h-full">
            {submitted ? (
                <div>
                    <h1 className="font-bold text-4xl md:text-5xl lg:text-5xl xl:text-5xl tracking-tighter text-center" style={{ color: `#2C3E50`}}>Great! We'll be in contact with you soon!</h1>
                    <img src={SuccessImg} height="400" width="700" alt="Form successfully submitted." />
                </div>   
            ) : ( infoSubmitted ? (
                <div>
                    <h1 style={{ color : `#2C3E50` }} className="font-medium tracking-tighter sm:text-6xl text-4xl mt-16 sm:mt-6 md:mt-6 lg:mt-6 xl:mt-6 text-center">Get a <span className="text-red-500"> Free </span> Quote!</h1>
                    <div className="flex flex-col items-center border border-gray-50 px-6 pb-8 h-full rounded-xl mx-4 sm:mx-0 shadow-md mt-6">
                        <h2 style={{ color : `#2C3E50` }} className="font-medium tracking-tighters mt-6 text-2xl py-4">Upload Your Bill</h2>
                        <iframe title="fileForm" loading="eager" scrolling="no" src={googleScript} width="100%" height="300px" style={{ height: `200px`}} className="overflow-hidden" />
                        <button onClick={setSubmitted} className="bg-red-500 transition duration-500 rounded-full h-16 px-8 text-white hover:shadow-md">Next</button>
                    </div>
                </div>
                ) : (
                <div>
                    <h1 style={{ color : `#2C3E50` }} className="font-medium tracking-tighter sm:text-6xl text-4xl mt-6 text-center">Connect With <span className="text-red-500"> Us</span> Today!</h1>
                    <form onSubmit={handleSubmit(onSubmitForm)} className="border border-gray-50 flex flex-col items-center content-between p-2 h-5/6 mt-8 rounded-xl mx-4 sm:mx-0 shadow-md">
                        <input
                            type="text" 
                            name="name"
                            {...register('name',{ required: {value: true, message: 'Please enter your name'}})}
                            placeholder="Full Name"
                            className="border border-gray-300 rounded-full w-11/12 h-14 mt-2 focus:outline-none focus:ring focus:ring-red-500 placeholder-gray-400 pl-4 focus:placeholder-opacity-50 text-gray-600"
                        />   
                        <span className="">{errors?.name?.message}</span>
                        <input
                            type="text" 
                            name="email"
                            {...register('email',{ 
                                required: {value: true, message: 'Please enter your email'},
                                minLength: {value: 8, message: 'Please enter a valid email'},
                                maxLength: {value: 120, message: 'Please enter a valid email'},
                                pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Please enter a valid email'},
                            })}
                            placeholder="Email"
                            className="border border-gray-300 rounded-full w-11/12 h-14 mt-4 focus:outline-none focus:ring focus:ring-red-500 placeholder-gray-400 pl-4 focus:placeholder-opacity-50 text-gray-600"
                        />
                        <span className="">{errors?.email?.message}</span>
                        <input
                            type="text" 
                            name="phone"
                            {...register('phone',{ 
                                required: {value: true, message: 'Please enter your phone number'},
                                minLength: {value: 10, message: 'Please enter a valid phone number'},
                                maxLength: {value: 10, message: 'Please enter a valid phone number'},
                                pattern: {value: /^[0-9\b]+$/i, message: 'Please enter a valid phone number'},
                            })}
                            placeholder="Phone"
                            className="border border-gray-300 rounded-full w-11/12 h-14 mt-4 focus:outline-none focus:ring focus:ring-red-500 placeholder-gray-400 pl-4 focus:placeholder-opacity-50 text-gray-600"
                        />
                        <span className="">{errors?.phone?.message}</span>
                        <textarea
                            type="text" 
                            name="message"
                            {...register('message',{ 
                                maxLength: {value: 1000, message: 'Please enter a shorter message'}
                            })}
                            placeholder="Message"
                            className="rounded-3xl border border-gray-300 w-11/12 h-64 mt-4 focus:outline-none focus:ring focus:ring-red-500 placeholder-gray-400 pl-4 pt-4 focus:placeholder-opacity-50 text-gray-600 resize-none"
                        />
                        <span className="">{errors?.message?.message}</span>
                        <button className="align-self-end bg-red-500 transition duration-500 rounded-full h-16 px-8 mt-4 text-white hover:shadow-md">Next</button>
                    </form>
                </div>
                )
            )
        }
        </main>
    );
}
 
export default Form;