import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import EnergySlide from "../assets/Carousel/energySavings.webp"
import SolarSlide from "../assets/Carousel/solar.webp"
import TechSlide from "../assets/Carousel/tech.webp"
import NatureSlide from "../assets/Carousel/nature.webp"
import FutureSlide from "../assets/Carousel/brighten.webp"

import './carousel.css';


const CarouselContainer = () => {
    return(
        <Carousel swipeable={false} stopOnHover={false} interval={5000} autoPlay infiniteLoop axis={'vertical'} showThumbs={false} showArrows={false} showIndicators={false} showStatus={false} className="sm:w-3/5 border-black" >
            <div>
                <img src={SolarSlide}  alt="Energy" />
                <p className="legend">ENERGIZE AND PROTECT YOUR FUTURE</p>
            </div>
            <div>
                <img src={FutureSlide} className="" alt="Energy" />
                <p className="legend">POWER COMMUNITIES, EMPOWER PEOPLE</p>
            </div>
            <div>
                <img src={EnergySlide} className="" alt="Energy" />
                <p className="legend">SAVE ENERGY, SAVE MONEY</p>
            </div>
            <div>
                <img src={NatureSlide} className="" alt="Energy" />
                <p className="legend">ENERGY INDEPENDENCE, SUSTAINABILITY</p>
            </div>
            <div>
                <img src={TechSlide} className="" alt="Energy" />
                <p className="legend">NAVIGATE YOUR FUTURE IN ENERGY</p>              
            </div>
        </Carousel>
    );
}

export default CarouselContainer;
 
