import { Helmet } from "react-helmet";
import { useEffect } from "react"
import AOS from 'aos';
import 'aos/dist/aos.css';

import PrivacyImg from "../assets/privacy.svg"

const PrivacyPolicy = () => {
    useEffect(() => {
        AOS.init();    
    }, []);
    return (
        <div className="flex flex-col justify-center">
            <Helmet>
                <title>Rave Energy | Privacy Policy</title>
                <meta name="keywords" content="HTML,CSS,JavaScript" />
                <meta
                    name="description"
                    content="Rave Energy privacy policy."
                />
            </Helmet>
            <h1 className="font-bold tracking-tighter text-5xl text-center my-8" style={{ color: `#2C3E50`}}>We care about your privacy</h1>
            <img src={PrivacyImg} className="mx-auto -mb-16 sm:-mb-24 md:-mb-24 lg:-mb-24 xl:-mb-24" height="400px" width="500px" alt="Privacy policy" />
            <div>
                <div className="tracking-tighter p-8 md:p-16 lg:p-16 text-white"style={{ background: `#00102f`}}>
                    <h1 className="text-4xl font-medium">Raveenergy.net Privacy Policy</h1>
                    <p className="text-2xl mt-4 font-light text-justify">If you have any additional questions or require more information about our Privacy Policy or about how we handle user data and personal information, or wish to withdraw your consent for the continued collection, use or disclosure of your Personal Information, please feel free to contact us.</p>
                </div>
                <div className="p-8 md:p-16 lg:p-16" style={{ color: `#2C3E50`}}>
                    <div data-aos-ease="ease" data-aos-duration="400" data-aos="fade-right" className="mt-16 tracking-tighter">
                        <h1 className="text-3xl font-medium">Definitions</h1>
                        <p className="mt-2 text-xl font-light text-justify">RAVEENERGY.NET is operated by Rave Energy, Dallas, TX. (referred to as "I", "Me", "Our", "Us" or "We"). As a customer of this service you're a "User", "Visitor" or "You" according to this agreement. The Website or any services offered by us will be referred to as "Website" or "Service". External services or integrations like will be referred to as Applications "External Provider" or "External Service".</p>
                    </div>
                    <div data-aos-ease="ease" data-aos-duration="400" data-aos="fade-right" className="mt-16 tracking-tighter">
                        <h1 className="text-3xl font-medium">Data We Collect</h1>
                        <p className="mt-2 text-xl font-light text-justify">Following standard procedures of using log files, these files automatically log visits when you visit the Website, it may include information about your web browser, it may log some of the cookies that are installed on your device, IP address, ISP, time zone and time stamp, referring/exit pages, number of clicks. The purpose of this information is for analyzing trends, administering this website, tracking users' movement on this website, and gathering demographic information, and it's not linked to any information that is personally identifiable. Additionally, as you browse the Website, we may collect information about subpages you view.</p>
                        <p className="mt-2 text-xl font-light text-justify">We may also collect personal information about you such as your name, organization/company name, address, email address or other personal data if you provide it to us during registration, purchasing, contacting us or in other ways. We only ask for personal information when we truly need it to provide a desired service to you (for example if you make an order through the Website). We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we're collecting it and how it will be used.</p>
                    </div>
                    <div data-aos-ease="ease" data-aos-duration="400" data-aos="fade-right" className="mt-16 tracking-tighter">
                        <h1 className="text-3xl font-medium">Sharing Your Personal Information</h1>
                        <p className="mt-2 text-xl font-light text-justify">We don't share, sell, trade or rent any personally identifying information publicly or with third-parties, except when required to by law, if you violate our Terms of Use or where it helps us facilitate our Service. For example online store module may require third-party services like PayPal or other Payment Processors, we may also use stats services like Google Analytics, Facebook Pixel and more. Each of these services have their own Privacy Policy, for example: PayPal Google Analytics In order to provide certain features we may rely on other third party services like payment and accounting providers or services, support or communication providers and other services, we reserve right to not track how you interact with any of the provided services. We may collect and use Non-Personal Information to help us improve the Service, customize your experience and analyze use of the Service. This Privacy Policy does not limit our use or disclosure of collected Non-Personal Information. We reserve the right to collect, use and disclose such Non-Personal Information to our partners, advertisers and other third parties at our sole discretion.</p>
                    </div>
                    <div data-aos-ease="ease" data-aos-duration="400" data-aos="fade-right" className="mt-16 tracking-tighter">
                        <h1 className="text-3xl font-medium">Cookies and Beacons</h1>
                        <p className="mt-2 text-xl font-light text-justify">RAVEENERGY.NET uses "Cookies", which are small data files placed on your device only if your browser's settings allow it and may include information like unique ID, previously visited subpages on this Website and/or other information. Types of Cookies we may use: Session Cookies, Security Cookies or Preference Cookies. You can disable or erase cookies in your browser settings.</p>
                    </div>
                    <div data-aos-ease="ease" data-aos-duration="400" data-aos="fade-right" className="mt-16 tracking-tighter">
                        <h1 className="text-3xl font-medium">General Data Protection Regulation (GDPR)</h1>
                        <p className="mt-2 text-xl font-light text-justify">The General Data Protection Regulation (GDPR) is a regulation in EU law on data protection and privacy for all individuals within the European Union and the European Economic Area.</p>
                        <p className="mt-2 text-xl font-light text-justify">If you are a European resident, we are Data Controller of your data and you have the right to know how your personal information is processed, what we collected about you and to ask that this data to be corrected, updated, or deleted. Additionally, please note that your information may be transferred outside of Europe, including to Canada and the United States. Please contact us if you have any questions about your GDPR rights. For more information or complaints please contact your local data protection authority.</p>
                    </div>
                    <div data-aos-ease="ease" data-aos-duration="400" data-aos="fade-right" className="mt-16 tracking-tighter">
                        <h1 className="text-3xl font-medium">Data Retention</h1>
                        <p className="mt-2 text-xl font-light text-justify">We retain collected information for as long as necessary to provide you with your requested service or unless and until you ask us to delete this information. Data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or modification. Customer passwords which we may store are is stored as a hash and original password cannot be recovered.</p>
                    </div>
                    <div data-aos-ease="ease" data-aos-duration="400" data-aos="fade-right" className="mt-16 tracking-tighter">
                        <h1 className="text-3xl font-medium">Email Communication</h1>
                        <p className="mt-2 text-xl font-light text-justify">We may communicate with you by email or telephone in response to your inquiries or send you service-related announcements based on Personal Information you provide to us. We may also use your Personal Information to send you updates and other promotional communications if we received your agreement. If you no longer wish to receive those email updates, you may opt-out at anytime of receiving them by following the instructions included in each communication. Usually it's an "unsubscribe" link.</p>
                    </div>
                    <div data-aos-ease="ease" data-aos-duration="400" data-aos="fade-right" className="mt-16 tracking-tighter">
                        <h1 className="text-3xl font-medium">Links To Other Websites</h1>
                        <p className="mt-2 text-xl font-light text-justify">Our Website may contain links to and from third party websites or services that are not operated by us. If you follow a link to any of these websites and you will be redirected to third part website, please note that these websites have their own privacy policies and we do not accept any responsibility or liability for these policies or practices. Please review the Privacy Policy of every website you visit before you submit any Personal Information to any of these websites.</p>
                    </div>
                    <div data-aos-ease="ease" data-aos-duration="400" data-aos="fade-right" className="mt-16 tracking-tighter">
                        <h1 className="text-3xl font-medium">Advertisements</h1>
                        <p className="mt-2 text-xl font-light text-justify">Our Website may display ads delivered to you by our advertising partners who may have their own Privacy Policy and may set cookies and web beacons. These cookies help ad server recognizing your device each time ad is delivered to you or other users of your device. This Privacy Policy covers the use of RAVEENERGY.NET only and does not cover any third party advertisers or partners.</p>
                    </div>
                    <div data-aos-ease="ease" data-aos-duration="400" data-aos="fade-right" className="mt-16 tracking-tighter">
                        <h1 className="text-3xl font-medium">Children</h1>
                        <p className="mt-2 text-xl font-light text-justify">This Website does not address any individuals under the age of 18. By using the Website, you represent that you are at least 18 years of age.</p>
                    </div>
                    <div data-aos-ease="ease" data-aos-duration="400" data-aos="fade-right" className="mt-16 tracking-tighter">
                        <h1 className="text-3xl font-medium">Do Not Track</h1>
                        <p className="mt-2 text-xl font-light text-justify">This Website does not alter its data collection and use practices when your browser sends Do Not Track signal</p>
                    </div>
                    <div data-aos-ease="ease" data-aos-duration="400" data-aos="fade-right" className="mt-16 tracking-tighter">
                        <h1 className="text-3xl font-medium">Business Transfers</h1>
                        <p className="mt-2 text-xl font-light text-justify">In the event RAVEENERGY.NET, or substantially all of our assets, were acquired, or in the unlikely event that Rave Energy goes out of business or enters bankruptcy, your personal information would be one of the assets that is transferred or acquired by a third party which may continue to process your Personal Information. You acknowledge and consent that such transfers may occur, and are permitted by this Privacy Policy, and that any acquirer of our assets may continue to use your personal information as set forth in this policy.</p>
                    </div>
                    <div data-aos-ease="ease" data-aos-duration="400" data-aos="fade-right" className="mt-16 tracking-tighter">
                        <h1 className="text-3xl font-medium">Changes</h1>
                        <p className="mt-2 text-xl font-light text-justify">We reserve the right to update or modify this Privacy Policy at anytime without prior notice. We will post these changes on this page so please review this document each time before you provide us with any personal information. This Policy is effective as of 2020/03/24. Your continued use of the Website after seven (7) days of any changes or revisions to this Privacy Policy shall indicate your agreement with the terms of such revised Privacy Policy. We suggest that you check this Privacy Policy periodically if you are concerned about how your information is used.</p>
                    </div>
                    <div data-aos-ease="ease" data-aos-duration="400" data-aos="fade-right" className="mt-16 tracking-tighter">
                        <h1 className="text-3xl font-medium">Consent</h1>
                        <p className="mt-2 text-xl font-light text-justify">By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default PrivacyPolicy;