import ContactForm from "../components/contactForm";
import { Helmet } from "react-helmet";

const Contact = () => {
    return (  
        <main>
            <Helmet>
                <title>Rave Energy | Contact</title>
                <meta name="keywords" content="HTML,CSS,JavaScript" />
                <meta
                    name="description"
                    content="Rave Energy contact page."
                />
            </Helmet>
            <ContactForm />
        </main>
    );
}
 
export default Contact;