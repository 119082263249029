import { useForm } from 'react-hook-form';
import ProspectDataServices from "../services/prospects"
import React from "react";

import Capture from "../assets/Technology/Capture.webp"

const Paywall = ( {route} ) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    async function onSubmitForm(values){
        const { name, email, phone, message } = values;
        var prospect = {
            name: name,
            email: email,
            phone: phone,
            message: message,
        };
        ProspectDataServices.createProspect(prospect)
        .then(response => {
            if(route === "procurement"){
                window.location.href = "https://ravepdfstorage.s3.us-east-2.amazonaws.com/Rave+Energy+-+A+Better+Way+to+Buy+.pdf" 
            }  
            else{
                window.location.href = "https://ravepdfstorage.s3.us-east-2.amazonaws.com/Rave-Energy-Management-Solution-Brief-2021+(1).pdf"
            }       
        })
        .catch(e =>{
            console.log(e);
        });
    }
    return ( 
        <main className="flex flex-col items-center py-12 lg:py-24 xl:py-24">
            <h1 style={{ color : `#2C3E50` }} className="text-center text-5xl md:text-6xl lg:text-6xl xl:text-6xl font-bold">Download PDF</h1>
            <div className="flex items-center justify-center mt-4 lg:mt-16 xl:mt-16 w-full">
                <form onSubmit={handleSubmit(onSubmitForm)} className="rounded-lg bg-white border border-gray-50 flex flex-col items-center p-3 mx-2 lg:mr-24 xl:mr-48 shadow-lg w-screen md:w-auto lg:w-auto xl:w-auto">
                    <input
                        type="text" 
                        name="name"
                        {...register('name',{ required: {value: true, message: 'Please enter your name'}})}
                        placeholder="Full Name"
                        className="rounded border border-gray-300 h-14 w-full md:w-96 lg:w-96 xl:w-96 mt-2 focus:outline-none focus:ring focus:ring-gray-700 placeholder-gray-400 pl-4 focus:placeholder-opacity-50 text-gray-600"
                    />   
                    <span className="">{errors?.name?.message}</span>
                    <input
                        type="text" 
                        name="email"
                        {...register('email',{ 
                            required: {value: true, message: 'Please enter your email'},
                            minLength: {value: 8, message: 'Please enter a valid email'},
                            maxLength: {value: 120, message: 'Please enter a valid email'},
                            pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Please enter a valid email'},
                        })}
                        placeholder="Email"
                        className="rounded border border-gray-300 h-14 w-full md:w-96 lg:w-96 xl:w-96 mt-4 focus:outline-none focus:ring focus:ring-gray-700 placeholder-gray-400 pl-4 focus:placeholder-opacity-50 text-gray-600"
                    />
                    <span className="">{errors?.email?.message}</span>
                    <input
                        type="text" 
                        name="phone"
                        {...register('phone',{ 
                            required: {value: true, message: 'Please enter your phone number'},
                            minLength: {value: 10, message: 'Please enter a valid phone number'},
                            maxLength: {value: 10, message: 'Please enter a valid phone number'},
                            pattern: {value: /^[0-9\b]+$/i, message: 'Please enter a valid phone number'},
                        })}
                        placeholder="Phone"
                        className="rounded border border-gray-300 h-14 w-full md:w-96 lg:w-96 xl:w-96 mt-4 focus:outline-none focus:ring focus:ring-gray-700 placeholder-gray-400 pl-4 focus:placeholder-opacity-50 text-gray-600"
                    />
                    <span className="">{errors?.phone?.message}</span>
                    <textarea
                        type="text" 
                        name="message"
                        {...register('message',{ 
                            maxLength: {value: 1000, message: 'Please enter a shorter message'}
                        })}
                        placeholder="Message"
                        className="rounded border border-gray-300 h-64 w-full md:w-96 lg:w-96 xl:w-96 mt-4 mb-4 focus:outline-none focus:ring focus:ring-gray-700 placeholder-gray-400 pl-4 pt-4 focus:placeholder-opacity-50 text-gray-600 resize-none"
                    />
                    <span className="">{errors?.message?.message}</span>
                  <button className="py-4 px-6 border-2 border-gray-400 rounded text-gray-500 hover:bg-gray-700 hover:text-white hover:border-transparent transition-all duration-300"> Click to download</button>
                </form>  
                <img alt="Rave Energy Technology" className="h-52 w-auto rounded-sm shadow-md hidden lg:block xl:block" src={Capture} height="100%" width="100%" />
            </div>             
        </main>
    );
}
 
export default Paywall;