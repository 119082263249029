import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { React, lazy, Suspense } from 'react';
import { Ring } from '@uiball/loaders'
import Home from "./pages/Home";
import Nav from "./components/Nav"
import Footer from "./components/Footer"
import ScrollToTop from "./components/ScrollToTop";
// import Demo from "./pages/Demo"; //This is a template you can rename to make a new page


import Contact from "./pages/Contact";
import Technology from "./pages/Technology";
import Procurement from "./pages/Procurement";
import Management from "./pages/Management";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import NotFound from "./pages/NotFound";
import ManagementDownload from "./pages/ManagementDownload"
import ProcurementDownload from "./pages/ProcurementDownload"

{/*const Nav = lazy(() => import("./components/Nav"))
const Footer = lazy(() => import("./components/Footer"))
const ScrollToTop = lazy(() => import("./components/ScrollToTop"))
//const Home = lazy(() => import("./pages/Home"))


const Contact = lazy(() => import("./pages/Contact"))
const Technology = lazy(() => import("./pages/Technology"))
const Procurement = lazy(() => import("./pages/Procurement"))
const Management = lazy(() => import("./pages/Management"))
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"))
const ManagementDownload = lazy(() => import("./pages/ManagementDownload"))
const ProcurementDownload = lazy(() => import("./pages/ProcurementDownload"))
const NotFound = lazy(() => import("./pages/NotFound"))
//lazy loading implemented to speed up website 
*/}





function App() {
  const renderLoader = () => <div className="flex justify-center" ><Ring size={35} color="#231F20" /></div>;
  return (

    <Router>
      <ScrollToTop />
      <div>
        <Nav />
        <div>
          {/* Anything inside the switch will become a path with a url mapping */}
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>
            {/* <Route exact path="/demo">
              <Demo /> //This is a template page whenever there is a need for a new page. you can just rename this module.
            </Route> */}
            <Route exact path="/technology">
              <Technology />
            </Route>
            <Route exact path="/technology/procurement">
              <Procurement />
            </Route>
            <Route exact path="/technology/management">
              <Management />
            </Route>
            <Route exact path="/technology/management/download">
              <ManagementDownload />
            </Route>
            <Route exact path="/technology/procurement/download">
              <ProcurementDownload />
            </Route>
            <Route exact path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </div>
        <Footer />
      </div>

    </Router>
  );
}

export default App;


