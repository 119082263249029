import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import Procu from "../assets/Technology/procu.webp"
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import GetAppIcon from '@material-ui/icons/GetApp';

const Procurement = () => {
    useEffect(() => {
        AOS.init({ duration: 1200 });    
    }, []);
    return (
        <main>
            <Helmet>
                <title>Rave Energy | Energy Procurement Management</title>
                <meta name="keywords" content="HTML,CSS,JavaScript" />
                <meta
                    name="description"
                    content="Rave Energy Energy Procurement Management"
                />
            </Helmet>
            <div style={{ color : `#2C3E50` }} className="flex flex-col items-center">
                <img alt="Procure" className="hidden lg:block w-screen h-96 object-cover object-bottom" src={Procu} height="100%" width="100%" />
                <h1 className="font-bold text-center tracking-tighter text-3xl md:text-4xl lg:text-5xl pt-24 sm:pt-24 md:pt-24 lg:pt-12 xl:pt-12">Energy Procurement Management</h1>
                <p className="sm:text-justify text-center text-xl max-w-4xl mt-4 px-4 md:px-0 lg:px-0">When hoping to obtain energy, your business should search for an energy provider that gives serious evaluating and an answer that addresses your issues explicitly. Numerous organizations have exceptional necessities for their energy use contingent upon the business, and blending up with the right provider guarantees more prominent proficiency.</p>
                <div className="flex justify-center"><Link style={{color: "00102f" }} to="/technology/procurement/download" target="_blank" className="font-semibold rounded-md bg-gray-200 hover:bg-gray-400 duration-300 my-8 py-4 px-4"><GetAppIcon className="mr-3" />Download a PDF for more Information</Link></div>
            </div>
        </main>
    );
}
 
export default Procurement;